<template>
<div>

  <v-card>
    <v-card-title>
      {{titulo}}
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="fas fa-search"
        label="Buscar"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>

      <!-- :items="currentItems" -->
    <v-data-table
      :headers="headers"
      :items="currentItems"
      :search="search"
      sort-by="id"
      sort-desc
      class="elevation-1"
      :loading="loading"
      loading-text="Consultando datos... Espere por favor"
    >
      <template v-slot:[`item.date_created`]="{ item }">
          <div>
            {{ dateFormat(item.date_created) }}
          </div>
      </template>
      <template v-slot:[`item.quantity`]="{ item }">
          <div v-if="item.gold_quantity > 0">
            {{ (parseFloat(item.quantity) + parseFloat(item.gold_quantity)).toFixed(2) }}
          </div>
          <div v-else>
            {{ parseFloat(item.quantity).toFixed(2) }}
          </div>
      </template>

      <template v-slot:top>
          <v-dialog v-model="dialog" max-width="500px">
            <v-card>
              <v-card-title>
                <span class="headline">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-form ref="form" v-model="valid">
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <v-autocomplete
                          v-model="editedItem.id_provider"
                          :items="customers"
                          item-text="nombre"
                          item-value="codigo"
                          label="Escoge un Cliente"
                          :rules="editedItem.id_providerRules"
                          clearable
                          rounded
                          solo
                          return-object
                        ></v-autocomplete>
                      </v-col>

                      <v-col cols="12" sm="12" md="12">
                        <v-text-field
                            v-model="editedItem.receipt"
                            label="Número de comprobante"
                            :rules="editedItem.receiptRules"
                            v-mask="'#######'"
                            required
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="dialog = false">Cancelar</v-btn>
                <v-btn color="blue darken-1" text @click="save">Guardar</v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- COMPONENTS -->
          <v-dialog persistent v-model="dialogNote" max-width="1000">
            <v-card> 
              <v-spacer></v-spacer>
              <div>
                <v-btn
                  style="right:10px; top:10px;"
                  icon
                  color="error"
                  fab
                  absolute
                  @click="close"
                >
                  <v-icon dark>fa-times</v-icon> 
                </v-btn>
              </div>

              <DeleteVouchers :key="editedItem.id" :bill="editedItem.id" @closeModal="closeModal($event)"  v-if="dialogNote"></DeleteVouchers>
            </v-card>
          </v-dialog>
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <v-icon color="success" class="mr-2" @click="editItem(item)">fas fa-edit</v-icon>
        <v-icon color="red" @click="deleteItem(item)">fas fa-trash-alt</v-icon>
      </template>

      <template slot="body.append">

      <tr class="gray--text">
        <!-- <th class="title"></th> -->
        
        <th colspan="9" class="totales">
        <v-row class="pt-2">

          <v-col cols="12" sm="12" md="4">
            Total Oro: {{ numberWithCommas((parseFloat(sumField('quantity'))).toFixed(2)) }}
          </v-col>
        </v-row>
        </th>
        
      </tr>
      </template>

    </v-data-table>
  </v-card>
</div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import axios from "axios";
import * as moment from 'moment';
import jsPDF from 'jspdf';
import n2wordsES from 'n2words/lib/i18n/ES.mjs'
import DeleteVouchers from '@/components/DeleteVouchers.vue';

export default {
  components: { DeleteVouchers },
  name: "constance",
  data: () => ({
    dialog: false,
    dialogNote: false,
    samplings: [],
    currentItems: [],
    sumItems: [],
    items: [],
    customers: [],
    process: "",
    type: "",
    total_sum: "",
    id_customer: null,
    dates: [],
    modal: false,
    loader: null,
    loading3: false,
    dialogDelete: false,
    search: "",
    loading: false,
    titulo: "Comprobantes de Retenciones",
    headers: [
      { text: "Fecha", align: "left", value: "date_created"},
      { text: "Facturado por", align: "left", value: "nombre" },
      { text: "Observación", align: "left", value: "observation" },
      { text: "Número de Comprobante", value: "receipt" },
      { text: "Retención", value: "quantity" },
      { text: "Oro(manual)", value: "gold_quantity", align: 'd-none'},
      { text: "Acciones", align: "center", value: "action", sortable: false }
    ],
    editedIndex: -1,

    valid: true,
    

    editedItem: {
      id_provider: null,
      receipt: "",
      selected: [],
      id_providerRules: [v => !!v || "Escoge un Productor"],
    },
    defaultItem: {
      id_provider: null,
      receipt: "",
      selected: [],
      id_providerRules: [v => !!v || "Escoge un Productor"],
    },
  }),

  computed: {
    
    formTitle() {
      return this.editedIndex === -1 ? "Nuevo Registro" : "Editar Registro";
    },
    ...mapState(["db","headers_db"]),
    dateRangeText: {
        get()
        {
            return this.dates.join(' ~ ')
        },
        set()
        {
            this.dates = []
        }
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close();
    // },
    loader () {
        const l = this.loader
        this[l] = !this[l]

        setTimeout(() => (this[l] = false), 1000)

        this.loader = null
    },
  },

  methods: {
    ...mapActions(["validateSession"]),
    // Metodos base de datos
    async getSamplings(){
      await axios.get(this.db + 'vouchers',
        {
          headers: this.headers_db
        }).then(response => {
          let datos2 = response.data.data;
          this.samplings = this.currentItems = datos2;
          console.log(datos2);
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getSamplings(); })
        });
    },

    async getCustomers(){
      await axios.get(this.db + 'providers_info',
        {
          headers: this.headers_db
        }).then(response => {
          this.customers = response.data.data;
          this.loading = false;
          // console.log(this.customers);
        })
        .catch((error) => {
          this.loading = false;
          this.validateSession(error).then((resp) => { if(resp) this.getCustomers(); })
        });
    },

    async getCompany(){
      await axios.get(this.db + 'company',
        {
          headers: this.headers_db
        }).then(response => {
          console.log(response.data.data);
          this.company = response.data.data;
        })
        .catch((error) => {
          this.validateSession(error).then((resp) => { if(resp) this.getCompany(); })
        });
    },
    // FIN Metodos base de datos

    // Metodos Generales

    closeModal(event){
      console.log("Cerrando modal Pesos");
      this.dialogNote = event;
      this.getSamplings();
    },
    
    dateFormat(date) {
      return moment(date).format('DD/MM/YYYY hh:mm a');
    },
    
    sumField(key) {
      return this.samplings.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },

    editItem(item) {
      this.editedIndex = this.samplings.indexOf(item);
      let id_provider2 = this.customers.filter(doc => {
        return doc.codigo == item.id_provider
      })[0];

      if (!item.id_provider.codigo) {
        item.id_provider = id_provider2;
      }
      
      console.log(item);
      this.dialog = true;
      this.editedItem = Object.assign({}, item);
    },

    deleteItem(item) {
      console.log(item.id);
      this.editedItem = Object.assign({}, item);
      this.dialogNote = true;
    },

    showModal2() {
      this.dialog = true;
    },

    close() {
      console.log("CERRANDO");
      this.dialog = false;
      this.dialogNote = false;
      this.dialogDelete = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.getSamplings();
      }, 300);
    },

    save() {
      
      if (this.$refs.form.validate()) {
        console.log(this.editedItem);
        axios.post(this.db + 'retentions/update', {
          receipt: this.editedItem.receipt,
          id_provider: this.editedItem.id_provider.codigo,
          id_pay_retention: this.editedItem.id,
        },
        {
          headers: this.headers_db
        })
        .then((response) => {
          console.log(response.data.message);
          Object.assign(this.samplings[this.editedIndex], this.editedItem);
          this.generatePdf(this.editedItem);
          this.$store.dispatch("setSnackbar", {
            text: response.data.message,
          });
          
          this.close();
        })
        .catch((error) => {
          console.log(error);
          this.$store.dispatch("setSnackbar", {
            text: error,
          });
        });
      }
    },

    numberWithCommas(x) {
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    // PURINGLA
    // async generatePdf(item) {
    //     console.log('ITEM',item);

    //     let total_cash = parseFloat(item.total_cash).toFixed(2);
        
    //     let total_ret = parseFloat(item.quantity).toFixed(2);

    //     // let ret_text = n2wordsES(total_ret).toUpperCase();
    //     // let cash_text = n2wordsES(total_cash).toUpperCase();

    //     const split_ret = total_ret.split(".");
    //     const qq = n2wordsES(split_ret[0]).toUpperCase();
    //     const lb = n2wordsES(split_ret[1]).toUpperCase();

    //     const split_cash = total_cash.split(".");
    //     const lps = n2wordsES(split_cash[0]).toUpperCase();
    //     const cent = n2wordsES(split_cash[1]).toUpperCase();

    //     let ret_text = qq + " QQS. CON " + lb + " LIBRAS ORO";
    //     let cash_text = lps + " LPS. CON " + cent + " CENTAVOS";
        
    //     console.log(total_ret, total_cash);
        
    //     moment.locale('es');
    //     let place_provider = item.id_provider.desbop;
    //     let day = moment().format("DD");
    //     let month = moment().format("MMMM").toUpperCase();
    //     let year = moment().format("YYYY");
    //     let provider = (item.id_provider.nombre).toUpperCase();
    //     let rtn = item.id_provider.rtn;
    //     let clave = item.id_provider.codihc;

    //     let place_owner = this.company.dire1;
    //     let owner = this.company.name2;
    //     let rtn_owner = this.company.rtn;
    //     let clave_owner = this.company.clave_owner;
    //     let operation_key = this.company.operation_key;
        
    //     var doc = new jsPDF();
    //     // doc.setFont("helvetica", "bold");
    //     doc.setFontSize(12)
        
    //     doc.text(place_owner, 53, 51);

    //     doc.text(day, 80, 60);
    //     doc.text(month, 118, 60, null, null, "center");
    //     doc.text(year, 164, 60, null, null, "center");

    //     doc.text(owner, 80, 68);

    //     doc.text(rtn_owner, 30, 77);
    //     doc.text(clave_owner, 120, 77);

    //     doc.text(provider, 90, 85);
        
    //     doc.text(rtn ? rtn : "", 30, 94);
    //     doc.text(clave ? clave : "", 120, 94);

    //     doc.text(place_provider ? place_provider : "", 75, 102);

    //     doc.text(item.id_provider.desmunp, 40, 110);
    //     doc.text(item.id_provider.desdepp, 130, 110);
        
    //     doc.text(item.id_provider.telprin ? item.id_provider.telprin : "", 35, 119);
    //     doc.text(item.id_provider.desbof, 130, 119);

    //     doc.text(item.id_provider.desmunf, 40, 127);
    //     doc.text(item.id_provider.desdepf, 130, 127);

    //     // beginY += 19;
    //     doc.text(split_ret[0] + ".", 21, 147);
    //     doc.text(split_ret[1], 46, 147);
    //     doc.text(ret_text, 60, 147);
        
    // //     beginY += 18;
    //     doc.text(this.numberWithCommas(split_cash[0]) + ".", 17, 166);
    //     doc.text(this.numberWithCommas(split_cash[1]), 46, 166);

    //     // doc.text(total_ret, 20, 147);
    //     // doc.text(ret_text, 60, 147);

    //     // doc.text(this.numberWithCommas(total_cash), 20, 166);

    //     if(cash_text.length > 50){
    //       let long = cash_text.length;
    //       let first_line = cash_text.substring(0, 50);
    //       let last_line = cash_text.substring(50, long);
    //       doc.text(first_line, 60, 164);
    //       doc.text(last_line, 60, 170);
    //     }
    //     else {
    //       doc.text(cash_text, 60, 164);
    //     } 

    //     doc.text(operation_key, 19, 184);

    //     doc.autoPrint();
    //     const blob = doc.output("bloburl");
    //     window.open(blob);
    // },

    async generatePdf(item) {
      console.log('ITEM',item);
      
      // let total_cash = parseFloat(item.total_cash).toFixed(2);
      let total_ret = parseFloat(item.quantity).toFixed(2);
      let price_oro = this.company.price_oro;
      let total_cash = (parseFloat(price_oro) * parseFloat(total_ret)).toFixed(2);

      // let ret_text = n2wordsES(total_ret).toUpperCase();
      // let cash_text = n2wordsES(total_cash).toUpperCase();

      const split_ret = total_ret.split(".");
      const qq = n2wordsES(split_ret[0]).toUpperCase();
      const lb = n2wordsES(split_ret[1]).toUpperCase();

      const split_cash = total_cash.split(".");
      const lps = n2wordsES(split_cash[0]).toUpperCase();
      const cent = n2wordsES(split_cash[1]).toUpperCase();

      let ret_text = qq + " QQS. CON " + lb + " LIBRAS ORO";
      let cash_text = lps + " LPS. CON " + cent + " CENTAVOS";
      
      console.log(total_ret, total_cash);
      
      moment.locale('es');
      let place_provider = item.id_provider.desbop;
      let day = moment().format("DD");
      let month = moment().format("MMMM").toUpperCase();
      let year = moment().format("YYYY");
      let provider = (item.id_provider.nombre).toUpperCase();
      let rtn = item.id_provider.rtn;
      let clave = item.id_provider.codihc;
      let place_owner = this.company.dire1.toUpperCase();
      console.log('place', place_owner);
      
      let owner = this.company.name2;
      
      let rtn_owner = this.company.rtn;
      let clave_owner = this.company.clave_owner;
      let operation_key = this.company.operation_key;
        
        var doc = new jsPDF();
        // doc.setFont("helvetica", "bold");
        doc.setFontSize(12)
        let beginY = parseInt(this.company.beginY);
        let beginX = parseInt(this.company.beginX);
        // console.log("rewr",beginY);
        doc.text(place_owner, 60, beginY);

        beginY += 9;
        doc.text(day, 85, beginY);
        doc.text(month, 123, beginY, null, null, "center");
        doc.text(year, 169, beginY, null, null, "center");

        beginY += 7;
        doc.text(owner, 85, beginY);

        beginY += 8;
        doc.text(rtn_owner, 35, beginY);
        doc.text(clave_owner, 125, beginY);
        
        beginY += 8;
        doc.text(provider, 95, beginY);
        
        beginY += 8;
        doc.text(rtn, 35, beginY);
        doc.text(clave, 125, beginY);
        
        beginY += 8;
        doc.text(place_provider, 80, beginY);
        
        beginY += 8;
        doc.text(item.id_provider.desmunp, 45, beginY);
        doc.text(item.id_provider.desdepp, 140, beginY);
        
        beginY += 8;
        doc.text(item.id_provider.telprin, 45, beginY);
        doc.text(item.id_provider.desbof, 140, beginY);
        
        beginY += 8;
        doc.text(item.id_provider.desmunf, 45, beginY);
        doc.text(item.id_provider.desdepf, 140, beginY);
        
        beginY += 18;
        doc.text(split_ret[0] + ".", 19 + beginX, beginY);
        doc.text(split_ret[1], 42 + beginX, beginY);
        doc.text(ret_text, 62, beginY);
        
        beginY += 18;
        doc.text(this.numberWithCommas(split_cash[0]) + ".", 15 + beginX, beginY);
        doc.text(this.numberWithCommas(split_cash[1]), 42 + beginX, beginY);

        // beginY += 19;

        if(cash_text.length > 50){
          let long = cash_text.length;
          let first_line = cash_text.substring(0, 50);
          let last_line = cash_text.substring(50, long);
          doc.text(first_line, 62, beginY - 2);
          doc.text(last_line, 62, beginY + 5);
        }
        else {
          doc.text(cash_text, 62, beginY - 1);
        }
        // doc.text("OCHO MIL CIENTO DIECIOCHO CON CERO CENTAVOS", 60, 158);
        beginY += 17;
        doc.text(operation_key, 25, beginY);

        doc.autoPrint();
        const blob = doc.output("bloburl");
        window.open(blob);
    },
    // async getAll() {
    //   this.loader = 'loading3'
    //   this.loading = true;
    //   await this.getSamplings();
    //   await this.getCustomers();
    // },
  },
  async mounted() {
    this.loading = true;
    await this.getSamplings();
    await this.getCustomers();
    await this.getCompany();
  },
};
</script>

<style lang="scss">
  .totales {
    font-size: medium !important;
  }
</style>
